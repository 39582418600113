<template>
  <div class="upload-file">
    <el-upload
      :action="uploadFileUrl"
      :before-upload="handleBeforeUpload"
      :file-list="fileList"
      multiple
      :limit="limit"
      :on-error="handleUploadError"
      :on-exceed="handleExceed"
      :on-success="handleUploadSuccess"
      :show-file-list="false"
      class="upload-file-uploader"
      ref="upload"
      :disabled="inputDisabled"
    >
      <!-- 上传按钮 -->
      <el-button size="mini" type="primary" :disabled="inputDisabled"
        >选取文件</el-button
      >
      <!-- 上传提示 -->
      <div class="el-upload__tip" slot="tip" v-if="showTip && !inputDisabled">
        请上传
        <template v-if="fileSize">
          大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b></template
        >
        <template v-if="fileType">
          格式为
          <b style="color: #f56c6c">{{ fileType.join("/") }}</b></template
        >
        的文件
      </div>
    </el-upload>

    <!-- 文件列表 -->
    <transition-group
      class="upload-file-list el-upload-list el-upload-list--text"
      name="el-fade-in-linear"
      tag="ul"
    >
      <li
        :key="file.fileId"
        class="el-upload-list__item ele-upload-list__item-content"
        v-for="(file, index) in fileArr"
      >
        <el-link
          :href="port + file.url"
          :underline="false"
          target="_blank"
          :download="file.name"
        >
          <span class="el-icon-document">{{ file.name }} </span>
        </el-link>
        <div class="ele-upload-list__item-content-action" v-if="!inputDisabled">
          <el-link :underline="false" @click="handleDelete(index)" type="danger"
            >删除</el-link
          >
        </div>
      </li>
    </transition-group>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { getFileInfo } from "@/api/system/fileUpload";
import { addTask } from "@/api/system/preview";

export default {
  props: {
    // 值
    value: {
      type: String,
      default: "",
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 10,
    },
    // 文件类型, 例如["doc", "xls", "ppt", "txt", "pdf"]
    fileType: {
      type: Array,
      default: () => ["doc", "xls", "ppt", "txt", "pdf"],
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true,
    },
    // 个数限制
    limit: {
      type: Number,
      default: 5,
    },
    disabled: Boolean,
    preview: {
      type: Boolean,
      default: false,
    },
  },
  inject: {
    elForm: {
      default: "",
    },
  },
  data() {
    return {
      uploadFileUrl: process.env.VUE_APP_BASE_API + "/common/upload",
      fileList: [],
      //附件ID
      fileIds: this.value ? this.value.split(",") : [],
      fileArr: [],
      port: process.env.VUE_APP_BASE_API,
    };
  },
  computed: {
    // 是否显示提示
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    },
    inputDisabled() {
      return this.disabled || (this.elForm || {}).disabled;
    },
  },
  methods: {
    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      // 校检文件类型
      if (this.fileType) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        const isTypeOk = this.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.toLowerCase().indexOf(type) > -1)
            return true;
          return false;
        });
        if (!isTypeOk) {
          this.$message.error(
            `文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`
          );
          return false;
        }
      }
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
      return true;
    },
    // 文件个数超出
    handleExceed() {
      this.$message.error(`只允许上传${this.limit}个文件`);
    },
    // 上传失败
    handleUploadError(err) {
      this.$message.error("上传失败, 请重试");
    },
    // 上传成功回调
    handleUploadSuccess(res, file, fileList) {
      this.$message.success("上传成功");
      var obj = {};
      obj.fileId = res.fileId;
      obj.name = res.fileName;
      obj.url = res.url;
      this.fileArr.push(obj);
      this.fileIds.push(res.fileId);
      // 如果为true，预览前先增加到队列
      if (this.preview) {
        addTask(obj.url);
      }
      this.$emit("input", this.fileIds.join(","));
    },
    // 删除文件
    handleDelete(index) {
      this.fileArr.splice(index, 1);
      this.fileIds.splice(index, 1);
      this.fileList.splice(index, 1);
      this.$emit("input", this.fileIds.join(","));
    },
  },
  created() {
    this.fileList = [];
    this.fileArr = [];
    //后台加载附件信息
    if (this.value) {
      getFileInfo(this.value).then((result) => {
        if (Array.isArray(result.data)) {
          result.data.forEach((item) => {
            var obj = {};
            obj.fileId = item.id;
            obj.name = item.fileName;
            obj.url = item.filePath;
            this.fileArr.push(obj);
          });
        }
      });
    }
  },
  watch: {
    value: {
      handler: function (newVal) {
        if (newVal) {
          this.fileList = [];
          this.fileArr = [];
          //后台加载附件信息
          if (this.value) {
            getFileInfo(this.value).then((result) => {
              if (Array.isArray(result.data)) {
                result.data.forEach((item) => {
                  var obj = {};
                  obj.fileId = item.id;
                  obj.name = item.fileName;
                  obj.url = item.filePath;
                  this.fileArr.push(obj);
                });
              }
            });
          }
        }
      },
      
    },
  },
};
</script>

<style scoped lang="scss">
.upload-file-uploader {
  margin-bottom: 5px;
}

.upload-file-list .el-upload-list__item {
  border: 1px solid #e4e7ed;
  line-height: 2;
  margin-bottom: 10px;
  position: relative;
}

.upload-file-list .ele-upload-list__item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
}

.ele-upload-list__item-content-action .el-link {
  margin-right: 10px;
}
</style>
