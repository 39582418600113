<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse}"
       :style="{
       backgroundColor: sideTheme === 'theme-dark' ? variables.menuBg : variables.menuLightBg,
       height : collapse ? '50px' : '200px'
       }">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
        <!--收缩-->
        <img :src="avatar" class="user-avatar-min">
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <!--展开-->
        <img :src="avatar" class="user-avatar">
        <p class="now-date">{{user.userName}}</p>
        <p class="now-date">{{date}}</p>
      </router-link>
    </transition>
  </div>
</template>

<script>
  import variables from '@/assets/styles/variables.scss'
  import {getUserProfile} from "@/api/system/user";
  import {mapGetters} from 'vuex'

  export default {
    name: 'SidebarLogo',
    props: {
      collapse: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      ...mapGetters([
        'sidebar',
        'avatar',
        'device'
      ]),
      variables() {
        return variables;
      },
      sideTheme() {
        return this.$store.state.settings.sideTheme
      }
    },
    data() {
      return {
        baseUrl: process.env.VUE_APP_BASE_API,
        date: '',
        user: {},
      };
    },
    methods: {
      getDate() {
        var dt = new Date();
        var year = dt.getFullYear(); //获取完整的年份(4位)
        var month = dt.getMonth() + 1; //获取当前月份(0-11,0代表1月)
        month = month < 10 ? "0" + month : month;
        var day = dt.getDate(); //获取当前日(1-31)
        day = day < 10 ? "0" + day : day;
        var hours = dt.getHours();//获取时
        hours = hours < 10 ? "0" + hours : hours;
        var min = dt.getMinutes();//获取分
        min = min < 10 ? "0" + min : min;
        var seconds = dt.getSeconds();//获取秒
        seconds = seconds < 10 ? "0" + seconds : seconds;
        return year + "年" + month + "月" + day + "日" + " " + hours + "时" + min + "分" + seconds + "秒";
      },
      getUser() {
        getUserProfile().then(response => {
          this.user = response.data;
        });
      }
    },
    mounted() {
      let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
      this.timer = setInterval(() => {
        _this.date = this.getDate(); // 修改数据date
      }, 1000)
    },
    beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
      }
    },
    created() {
      this.getUser();
      this.date = this.getDate();
    },
  }
</script>

<style lang="scss" scoped>
  .sidebarLogoFade-enter-active {
    transition: opacity 1.5s;
  }

  .sidebarLogoFade-enter,
  .sidebarLogoFade-leave-to {
    opacity: 0;
  }

  .sidebar-logo-container {
    position: relative;
    width: 100%;
    background: #2b2f3a;
    text-align: center;
    overflow: hidden;

    & .sidebar-logo-link {
      height: 100%;
      width: 100%;

      & .sidebar-logo {
        width: 32px;
        height: 32px;
        vertical-align: middle;
        margin-right: 12px;
      }

      & .sidebar-title {
        display: inline-block;
        margin: 0;
        color: #fff;
        font-weight: 600;
        font-size: 14px;
        font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
        vertical-align: middle;
      }
    }

    &.collapse {
      .sidebar-logo {
        margin-right: 0px;
      }
    }

    .user-avatar {
      margin-top: 10px;
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }

    .user-avatar-min {
      margin-top: 5px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .now-date {
      height: 20px;
      font-size: 13px;
      color: #FFF;
      font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  }
</style>
