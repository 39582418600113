var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-file" },
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          staticClass: "upload-file-uploader",
          attrs: {
            action: _vm.uploadFileUrl,
            "before-upload": _vm.handleBeforeUpload,
            "file-list": _vm.fileList,
            multiple: "",
            limit: _vm.limit,
            "on-error": _vm.handleUploadError,
            "on-exceed": _vm.handleExceed,
            "on-success": _vm.handleUploadSuccess,
            "show-file-list": false,
            disabled: _vm.inputDisabled,
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                size: "mini",
                type: "primary",
                disabled: _vm.inputDisabled,
              },
            },
            [_vm._v("选取文件")]
          ),
          _vm.showTip && !_vm.inputDisabled
            ? _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _vm._v(" 请上传 "),
                  _vm.fileSize
                    ? [
                        _vm._v(" 大小不超过 "),
                        _c("b", { staticStyle: { color: "#f56c6c" } }, [
                          _vm._v(_vm._s(_vm.fileSize) + "MB"),
                        ]),
                      ]
                    : _vm._e(),
                  _vm.fileType
                    ? [
                        _vm._v(" 格式为 "),
                        _c("b", { staticStyle: { color: "#f56c6c" } }, [
                          _vm._v(_vm._s(_vm.fileType.join("/"))),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" 的文件 "),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition-group",
        {
          staticClass: "upload-file-list el-upload-list el-upload-list--text",
          attrs: { name: "el-fade-in-linear", tag: "ul" },
        },
        _vm._l(_vm.fileArr, function (file, index) {
          return _c(
            "li",
            {
              key: file.fileId,
              staticClass: "el-upload-list__item ele-upload-list__item-content",
            },
            [
              _c(
                "el-link",
                {
                  attrs: {
                    href: _vm.port + file.url,
                    underline: false,
                    target: "_blank",
                    download: file.name,
                  },
                },
                [
                  _c("span", { staticClass: "el-icon-document" }, [
                    _vm._v(_vm._s(file.name) + " "),
                  ]),
                ]
              ),
              !_vm.inputDisabled
                ? _c(
                    "div",
                    { staticClass: "ele-upload-list__item-content-action" },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { underline: false, type: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(index)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }