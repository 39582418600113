var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "component-upload-image" },
    [
      _c(
        "el-upload",
        {
          class: { hide: _vm.inputDisabled },
          attrs: {
            action: _vm.uploadImgUrl,
            multiple: "",
            limit: _vm.limit,
            "list-type": "picture-card",
            "file-list": _vm.fileList,
            "on-preview": _vm.handlePictureCardPreview,
            "on-remove": _vm.handleRemove,
            "on-error": _vm.handleError,
            "on-success": _vm.handleAvatarSuccess,
            "before-upload": _vm.handleBeforeUpload,
            "on-exceed": _vm.handleExceed,
            disabled: _vm.inputDisabled,
          },
        },
        [
          _c("i", { staticClass: "el-icon-plus" }),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [
              _vm._v(" 请上传 "),
              _vm.fileSize
                ? [
                    _vm._v(" 大小不超过 "),
                    _c("b", { staticStyle: { color: "#f56c6c" } }, [
                      _vm._v(_vm._s(_vm.fileSize) + "MB"),
                    ]),
                  ]
                : _vm._e(),
              _vm.fileType
                ? [
                    _vm._v(" 格式为 "),
                    _c("b", { staticStyle: { color: "#f56c6c" } }, [
                      _vm._v(_vm._s(_vm.fileType.join("/"))),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" 的图片, "),
              _vm.limit
                ? [
                    _vm._v(" 数量不超过 "),
                    _c("b", { staticStyle: { color: "#f56c6c" } }, [
                      _vm._v(_vm._s(_vm.limit) + "张"),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, "append-to-body": "" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { height: "60vh", overflow: "hidden" } }, [
            _c("img", {
              attrs: {
                width: "100%",
                height: "100%",
                src: _vm.dialogImageUrl,
                alt: "",
              },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }