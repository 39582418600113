const dictionaries = {
  state: {
    info: { label: '未知', value: 1, type: 'info' },
    yes_or_no: [
      { label: '是', value: 1, type: 'primary' },
      { label: '否', value: 0, type: 'danger' }
    ],
    
    detailStatus:[
      { label: '平台处理中', value: 1, type: 'primary' },
      { label: '平台审核不通过', value: 2, type: 'danger' },
      { label: '营商办处理中', value: 3, type: 'primary' },
      { label: '委办单位受理中', value: 4, type: 'danger' },
      { label: '委办单位已受理', value: 5, type: 'success' },
      { label: '用户待评价', value: 6, type: 'primary' },
      { label: '待回访', value: 7, type: 'danger' },
      { label: '已回访', value: 8, type: 'success' },
    ],
    
    respLimit:[
      { label: '4小时', value: 1, type: 'primary' },
      { label: '8小时', value: 2, type: 'danger' }
    ],
    finishLimit:[
      { label: '3天', value: 1, type: 'primary' },
      { label: '7天', value: 2, type: 'danger' },
      { label: '14天', value: 3, type: 'danger' },
      { label: '21天', value: 4, type: 'danger' },
      { label: '30天', value: 5, type: 'danger' }
    ],
    appealStatus:[
      { label: '处理中', value: 1, type: 'primary' },
      { label: '审核不通过', value: 2, type: 'primary' },
      { label: '已受理', value: 3, type: 'primary' },
      { label: '待评价', value: 4, type: 'primary' },
      { label: '待回访', value: 5, type: 'primary' },
      { label: '已完结', value: 6, type: 'primary' }
    ],
    companyRegionType:[
      { label: '区内企业', value: 1, type: 'primary' },
      { label: '区外企业', value: 2, type: 'primary' },
    ],
    registerCheckStatus:[
      { label: '待审核', value: 1, type: 'primary' },
      { label: '审核通过', value: 2, type: 'success' },
      { label: '审核不通过', value: 3, type: 'danger' },
    ],
    moveOutStatus:[
      { label: '审核中', value: 1, type: 'primary' },
      { label: '审核通过', value: 2, type: 'success' },
      { label: '审核不通过', value: 3, type: 'danger' }
    ],
    
  },
  mutations: {

  },
  actions: {

  }
}

export default dictionaries
