import request from '@/utils/request'
import cryptoJs from 'crypto-js';
import {getLoginKey} from '@/utils/jsencrypt';


//var iv = cryptoJs.enc.Utf8.parse('1234567812345678');

/* 加密 */
function  encryptFunc(word, keyStr)  {
  var key = cryptoJs.enc.Utf8.parse(keyStr);
  var encryptedObj = cryptoJs.AES.encrypt(word, key, {

    mode: cryptoJs.mode.ECB,
    padding: cryptoJs.pad.Pkcs7
  });
  return encryptedObj.toString();
}
// 登录方法
export function login(username, password, code, uuid) {

  password = encryptFunc(password,getLoginKey());
  console.log(password);
  const data = {
    username,
    password,
    code,
    uuid
  }
  return request({
    url: '/login',
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    method: 'get'
  })
}
