import Vue from 'vue'

import Cookies from 'js-cookie'

import Element from 'element-ui'
import './assets/styles/element-variables.scss'

import '@/assets/styles/index.scss' // global css
import '@/assets/styles/zudp.scss' // zudp css
import App from './App'
import store from './store'
import router from './router'
import permission from './directive/permission'

import Chat from 'jwchat';
import './assets/icons' // icon
import './permission' // permission control
import { getDicts,getDictsNoPerm } from "@/api/system/dict/data";
import { getRooms } from "@/api/system/dict/data";
import { getConfigKey } from "@/api/system/config";
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, download, handleTree, handImg } from "@/utils/zudp";
import Pagination from "@/components/Pagination";
// 自定义表格工具扩展
import RightToolbar from "@/components/RightToolbar"

// 图片上传组件
import ImageUpload from "@/components/ImageUpload"

// 文件上传组件
import FileUpload from "@/components/FileUpload"

//全局禁止点击遮罩层关闭el-dialog弹窗
Element.Dialog.props.closeOnClickModal.default = false;

// 

// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getDictsNoPerm = getDictsNoPerm
Vue.prototype.getRooms = getRooms
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree
Vue.prototype.handImg = handImg


Vue.prototype.$returnInfo = (arr, val) => {
    let tabs = arr.find(it => it.value+'' === val+'')
    if (!tabs) {
      tabs = store.state.dictionaries.info
    }
    return tabs
}


Vue.prototype.msgSuccess = function(msg) {
    this.$message({ showClose: true, message: msg, type: "success" });
}

Vue.prototype.msgError = function(msg) {
    this.$message({ showClose: true, message: msg, type: "error" });
}

Vue.prototype.msgInfo = function(msg) {
    this.$message.info(msg);
}

// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)

Vue.use(permission)
Vue.use(Chat)
Vue.component('ImageUpload', ImageUpload)
Vue.component('FileUpload', FileUpload)

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
    size: Cookies.get('size') || 'medium' // set element-ui default size
})

Vue.config.productionTip = false

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})