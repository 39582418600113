import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'

NProgress.configure({ showSpinner: false })

const whiteList = ['/','/login', '/auth-redirect', '/bind', '/register', '/gateway', '/gatewaylogin', '/gatewaypolicy', '/gatewaymatch', '/gatewaycerebrum', '/gatewaymyxs', '/gatewayrent', '/gatewaylaw', '/gatewayrecruiting', '/gatewayconsult', '/gatewayactivity',
    '/gatewayhome', '/gatewaysnews', '/huiqiPolicy', '/gatewayslist', '/gatewayshenbaoCenter', '/gatewayestablish', '/gatewayfinance', '/gatewayinnovate', '/gatewayqualifications', '/ysnewslist', '/gatewayysindex', '/gatewayyspolicylist', '/gatewayysnewslist',
  '/gatewayysanalyzelist','/gatewaybrain','/search', '/gatewayconsultList',  '/gateway/appealSearch','/gateway/appeal/index'
]

const blackList = ['/gateway/appeal/new', '/gateway/appeal/mine']


router.beforeEach((to, from, next) => {
    NProgress.start()
    if (to.path == '/' || to.path == '') {
        localStorage.setItem('isFront', 1)
    } else {
        localStorage.setItem('isFront', '')
    }
    if (getToken()) {
        /* has token*/
        if (to.path === '/login') {
            next({ path: '/' })
            NProgress.done()
        } else {
            if (store.getters.roles.length === 0) {
                // 判断当前用户是否已拉取完user_info信息
                store.dispatch('GetInfo').then(() => {
                    store.dispatch('GenerateRoutes').then(accessRoutes => {
                        // 根据roles权限生成可访问的路由表
                        router.addRoutes(accessRoutes) // 动态添加可访问路由表
                        next({...to, replace: true }) // hack方法 确保addRoutes已完成
                    })
                }).catch(err => {
                    store.dispatch('LogOut').then(() => {
                        Message.error(err)
                        next({ path: '/' })
                    })
                })
            } else {
                next()
            }
        }
    } else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        }
        else if(blackList.indexOf(to.path) !== -1){
            next(`/gatewaylogin?id=1`) // 重定向到登录页
            NProgress.done()
        }
        else {
            next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})
