import request from '@/utils/request'

// 查询附件信息
export function getFileInfo(fileIds) {
  return request({
    url: '/common/fileInfo/' + fileIds,
    method: 'get',
    headers: {
      isToken: false
    }
  })
}

