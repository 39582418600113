import request from '@/utils/request'
// 预览前先增加到队列
export function addTask(path) {
  const query = {
    path: path
  }
  return request({
    url: '/system/preview/addTask',
    method: 'get',
    params: query
  })
}

export function onlinePreview(path) {
  const query = {
    path: path
  }
  return request({
    url: '/system/preview/onlinePreview',
    method: 'get',
    params: query
  })
}

