import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import ParentView from '@/components/ParentView';

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    noCache: true                // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

// 公共路由
export const constantRoutes = [
    {
        path: '/',
        component: (resolve) => require(['@/views/gateway/home/index'], resolve),
        hidden: true
    },
    {
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [{
            path: '/redirect/:path(.*)',
            component: (resolve) => require(['@/views/redirect'], resolve)
        }]
    },
    {
        path: '/gatewaylogin',
        component: (resolve) => require(['@/views/gateway/login/login'], resolve),
        hidden: true
    },
    {
        path: '/login',
        component: (resolve) => require(['@/views/login'], resolve),
        hidden: true
    },
    {
        path: '/gatewaypolicy',
        component: (resolve) => require(['@/views/gateway/policy/index'], resolve),
        hidden: true
    },
    {
      path: '/gatewaybrain',
        component: (resolve) => require(['@/views/gateway/policy/brain'], resolve),
      hidden: true
    },
    {
        path: '/gatewaycerebrum',
        component: (resolve) => require(['@/views/gateway/policy/cerebrum'], resolve),
        hidden: true
    },
    {
        path: '/gatewaycompile',
        component: (resolve) => require(['@/views/gateway/policy/compile'], resolve),
        hidden: true
    },
    {
        path: '/gateway',
        component: (resolve) => require(['@/views/gateway/home/index'], resolve),
        hidden: true
    },
    {
        path: '/huiqiPolicy',
        component: (resolve) => require(['@/views/gateway/huiqiPolicy/index'], resolve),
        hidden: true
    },
    {
        path: '/gatewaysnews',
        component: (resolve) => require(['@/views/gateway/information/news'], resolve),
        hidden: true
    },
    {
        path: '/gateway/appealSearch',
        component: (resolve) => require(['@/views/gateway/service/appeal/appealSearch'], resolve),
        hidden: true
    },
    {
        path: '/gateway/appeal/mine',
        component: (resolve) => require(['@/views/gateway/service/appeal/mineAppeal'], resolve),
        hidden: true
    },
    {
        path: '/gateway/appeal/new',
        component: (resolve) => require(['@/views/gateway/service/appeal/newAppeal'], resolve),
        hidden: true
    },

    {
        path: '/gateway/appeal/index',
        component: (resolve) => require(['@/views/gateway/service/appeal/appealIndex'], resolve),
        hidden: true
    },

    {
        path: '/gatewaymyxs',
        component: (resolve) => require(['@/views/gateway/my/myxs'], resolve),
        hidden: true
    },
    {
        path: '/gatewaymatch',
        component: (resolve) => require(['@/views/gateway/policy/cerebrum'], resolve),
        hidden: true
    },
    {
        path: '/gatewayrent',
        component: (resolve) => require(['@/views/gateway/building/rent'], resolve),
        hidden: true
    },
    {
        path: '/gatewayactivity',
        component: (resolve) => require(['@/views/gateway/information/activity'], resolve),
        hidden: true
    },
    {
        path: '/gatewayshenbaoCenter',
        component: (resolve) => require(['@/views/gateway/information/shenbaoCenter'], resolve),
        hidden: true
    },
    {
        path: '/gatewaypersonal',
        component: (resolve) => require(['@/views/gateway/banshi/personal'], resolve),
        hidden: true
    },
    {
        path: '/gatewayconsult',
        component: (resolve) => require(['@/views/gateway/service/consult'], resolve),
        hidden: true
    },
    {
      path: '/gatewayconsultList',
      component: (resolve) => require(['@/views/gateway/service/consultList'], resolve),
      hidden: true
    },
    {
        path: '/gatewayestablish',
        component: (resolve) => require(['@/views/gateway/service/establish'], resolve),
        hidden: true
    },
    {
        path: '/gatewayysindex',
        component: (resolve) => require(['@/views/gateway/yinshang/ysIndex'], resolve),
        hidden: true
    },
    {
        path: '/gatewayysanalyzelist',
        component: (resolve) => require(['@/views/gateway/yinshang/ysAnalyze'], resolve),
        hidden: true
    },
    {
      path: '/gatewayysanalyzedetails',
        component: (resolve) => require(['@/views/gateway/yinshang/ysAnalyzeDetails'], resolve),
      hidden: true
    },
    {
        path: '/gatewayysnewsdetails',
        component: (resolve) => require(['@/views/gateway/yinshang/ysNewsDetails'], resolve),
        hidden: true
    },
    {
        path: '/gatewayysnewslist',
        component: (resolve) => require(['@/views/gateway/yinshang/ysNewsList'], resolve),
        hidden: true
    },
    {
        path: '/gatewayyspolicy',
        component: (resolve) => require(['@/views/gateway/yinshang/ysPolicyDetails'], resolve),
        hidden: true
    },
    {
        path: '/gatewayyspolicylist',
        component: (resolve) => require(['@/views/gateway/yinshang/ysPolicyList'], resolve),
        hidden: true
    },
    {
        path: '/gatewayfinance',
        component: (resolve) => require(['@/views/gateway/service/finance'], resolve),
        hidden: true
    },
    {
        path: '/gatewayinnovate',
        component: (resolve) => require(['@/views/gateway/service/innovate'], resolve),
        hidden: true
    },
    {
        path: '/gatewayqualifications',
        component: (resolve) => require(['@/views/gateway/service/qualifications'], resolve),
        hidden: true
    },
    {
        path: '/gatewaylaw',
        component: (resolve) => require(['@/views/gateway/service/law'], resolve),
        hidden: true
    },
    {
        path: '/gatewayrecruiting',
        component: (resolve) => require(['@/views/gateway/service/recruiting'], resolve),
        hidden: true
    },
    {
        path: '/search',
        component: (resolve) => require(['@/views/gateway/home/search'], resolve),
        hidden: true
    },
    {
        path: '/404',
        component: (resolve) => require(['@/views/error/404'], resolve),
        hidden: true
    },
    {
        path: '/401',
        component: (resolve) => require(['@/views/error/401'], resolve),
        hidden: true
    },
    {
        path: '',
        component: Layout,
        redirect: 'index',
        children: [{
            path: 'index',
            component: (resolve) => require(['@/views/index'], resolve),
            name: '首页',
            meta: { title: '首页', icon: 'dashboard', noCache: true, affix: true }
        }]
    },
    {
        path: '/user',
        component: Layout,
        hidden: true,
        redirect: 'noredirect',
        children: [{
            path: 'profile',
            component: (resolve) => require(['@/views/system/user/profile/index'], resolve),
            name: 'Profile',
            meta: { title: '个人中心', icon: 'user' }
        }]
    },
    {
        path: '/dict',
        component: Layout,
        hidden: true,
        children: [{
            path: 'type/data/:dictId(\\d+)',
            component: (resolve) => require(['@/views/system/dict/data'], resolve),
            name: 'Data',
            meta: { title: '字典数据', icon: '' }
        }]
    },
    {
        path: '/system/oss-config',
        component: Layout,
        hidden: true,
        children: [
            {
            path: 'index',
            component: (resolve) => require(['@/views/system/oss/config'], resolve),
            name: 'OssConfig',
            meta: { title: '配置管理', activeMenu: '/system/oss'}
            }
        ]
    },
    {
        path: '/job',
        component: Layout,
        hidden: true,
        children: [{
            path: 'log',
            component: (resolve) => require(['@/views/monitor/job/log'], resolve),
            name: 'JobLog',
            meta: { title: '调度日志' }
        }]
    },
    {
        path: '/gen',
        component: Layout,
        hidden: true,
        children: [{
            path: 'edit/:tableId(\\d+)',
            component: (resolve) => require(['@/views/tool/gen/editTable'], resolve),
            name: 'GenEdit',
            meta: { title: '修改生成配置' }
        }]
    },
    {
        path: '/topic',
        component: Layout,
        hidden: true,
        children: [{
            path: 'select/:paperId(\\d+)',
            component: (resolve) => require(['@/views/system/paper/selectTopic'], resolve),
            name: 'TopicSelect',
            meta: { title: '试卷题目' }
        }]
    },

    {
        path: '/collection',
        component: Layout,
        hidden: true,
        children: [{
            path: 'select/:paperId/',
            component: (resolve) => require(['@/views/collection/info/index'], resolve),
            name: 'TopicSelect',
            meta: { title: '附件藏品' }
        }]
    },
    {
        path: '/collection',
        component: Layout,
        hidden: true,
        children: [{
            path: 'select/:paperId/:tableName',
            component: (resolve) => require(['@/views/collection/info/index'], resolve),
            name: 'TopicSelect',
            meta: { title: '附件藏品' }
        }]
    },
    {
        path: '/topic',
        component: Layout,
        hidden: true,
        children: [{
            path: 'add/:paperId(\\d+)',
            component: (resolve) => require(['@/views/system/paper/addTopic'], resolve),
            name: 'TopicSelect',
            meta: { title: '选择题目' }
        }]
    },
    {
        path: "/homeManage",
        component: Layout,
        hidden: true,
        children: [{
            path: "work",
            component: (resolve) => require(['@/views/business/work/index'], resolve),
            name: "工作动态",
            meta: { title: '工作动态' }
        }]
    },
    {
        path: "/module",
        component: Layout,
        hidden: true,
        children: [{
            path: "policy",
            component: (resolve) => require(['@/views/business/policy/index'], resolve),
            name: "政策管理",
            meta: { title: '政策管理' }
        }]
    },
    {
        path: "/module",
        component: Layout,
        hidden: true,
        children: [{
            path: "compile",
            component: (resolve) => require(['@/views/business/compile/index'], resolve),
            name: "政策汇编",
            meta: { title: '政策汇编' }
        }]
    },
    {
        path: "/module",
        component: Layout,
        hidden: true,
        children: [{
            path: "report",
            component: (resolve) => require(['@/views/business/report/index'], resolve),
            name: "申报管理",
            meta: { title: '申报管理' }
        }]
    },
    {
        path: "/service",
        component: Layout,
        hidden: true,
        children: [{
            path: "voice",
            component: (resolve) => require(['@/views/business/voice/index'], resolve),
            name: "企业心声",
            meta: { title: '企业心声' }
        }]
    },
    {
        path: "/service",
        component: Layout,
        hidden: true,
        children: [{
            path: "enterpriseReport",
            component: (resolve) => require(['@/views/business/enterpriseReport/index'], resolve),
            name: "企业申报管理",
            meta: { title: '企业申报管理' }
        }]
    },
    {
        path: "/module",
        component: Layout,
        hidden: true,
        children: [{
            path: "move",
            component: (resolve) => require(['@/views/business/move/index'], resolve),
            name: "活动管理",
            meta: { title: '活动管理' }
        }]
    },
    {
        path: "/info",
        component: Layout,
        hidden: true,
        children: [{
            path: "legal",
            component: (resolve) => require(['@/views/business/legal/index'], resolve),
            name: "法律服务",
            meta: { title: '法律服务' }
        }]
    },
    {
        path: "/info",
        component: Layout,
        hidden: true,
        children: [{
            path: "register",
            component: (resolve) => require(['@/views/business/register/index'], resolve),
            name: "注册成立",
            meta: { title: '注册成立' }
        }]
    },
    {
        path: "/info",
        component: Layout,
        hidden: true,
        children: [{
            path: "personnel",
            component: (resolve) => require(['@/views/business/personnel/index'], resolve),
            name: "人才服务",
            meta: { title: '人才服务' }
        }]
    },
    {
        path: "/info",
        component: Layout,
        hidden: true,
        children: [{
            path: "innovate",
            component: (resolve) => require(['@/views/business/innovate/index'], resolve),
            name: "创新研究",
            meta: { title: '创新研究' }
        }]
    },
    {
        path: "/info",
        component: Layout,
        hidden: true,
        children: [{
            path: "enterprise",
            component: (resolve) => require(['@/views/business/enterprise/index'], resolve),
            name: "企业资质",
            meta: { title: '企业资质' }
        }]
    },
    {
        path: "/info",
        component: Layout,
        hidden: true,
        children: [{
            path: "financial",
            component: (resolve) => require(['@/views/business/financial/index'], resolve),
            name: "金融服务",
            meta: { title: '金融服务' }
        }]
    },
    {
        path: "/service",
        component: Layout,
        hidden: true,
        children: [{
            path: "browsing",
            component: (resolve) => require(['@/views/business/browsing/index'], resolve),
            name: "问答浏览",
            meta: { title: '问答浏览' }
        }]
    },
    {
        path: "/company",
        component: Layout,
        hidden: true,
        children: [{
            path: "cash",
            component: (resolve) => require(['@/views/business/cash/index'], resolve),
            name: "惠企兑现",
            meta: { title: '惠企兑现' }
        }]
    },
    {
        path: "/module",
        component: Layout,
        hidden: true,
        children: [{
            path: "link",
            component: (resolve) => require(['@/views/business/link/index'], resolve),
            name: "办事链接",
            meta: { title: '办事链接' }
        }]
    }
]

export default new Router({
    mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})
