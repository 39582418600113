<template>
  <div class="component-upload-image">

    <el-upload
      :action="uploadImgUrl"
      multiple
      :limit="limit"
      list-type="picture-card"
      :file-list="fileList"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-error="handleError"
      :on-success="handleAvatarSuccess"
      :before-upload="handleBeforeUpload"
      :on-exceed="handleExceed"
      :class="{hide:inputDisabled}"
      :disabled="inputDisabled"
    >
      <i class="el-icon-plus"></i>
      <!-- 上传提示 -->
      <div class="el-upload__tip" slot="tip">
        请上传
        <template v-if="fileSize"> 大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b></template>
        <template v-if="fileType"> 格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b></template>
        的图片,
        <template v-if="limit"> 数量不超过 <b style="color: #f56c6c">{{ limit }}张</b></template>

      </div>
    </el-upload>

    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <div style="height:60vh;overflow: hidden">
        <img width="100%" height="100%" :src="dialogImageUrl" alt="">
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {getToken} from "@/utils/auth";
  import {getFileInfo} from "@/api/system/fileUpload";

  export default {
    inject: {
      elForm: {
        default: ''
      }
    },
    data() {
      return {
        uploadImgUrl: process.env.VUE_APP_BASE_API + "/common/upload", // 上传的图片服务器地址
        fileList: [],
        port: process.env.VUE_APP_BASE_API,
        dialogImageUrl: '',
        dialogVisible: false,
        //附件ID
        fileIds: this.value ? this.value.split(",") : [],
      };
    },
    props: {
      value: {
        type: String,
        default: "",
      },
      // 大小限制(MB)
      fileSize: {
        type: Number,
        default: 5,
      },
      // 文件类型, 例如['png', 'jpg', 'jpeg']
      fileType: {
        type: Array,
        default: () => ['png', 'jpg', 'jpeg'],
      },
      // 个数限制
      limit: {
        type: Number,
        default: 5
      },
      disabled: Boolean
    },
    computed: {
      inputDisabled() {
        return this.disabled || (this.elForm || {}).disabled;
      },
    },
    methods: {
      // 上传前校检格式和大小
      handleBeforeUpload(file) {
        // 校检文件类型
        // debugger
        if (this.fileType) {
          let fileExtension = "";
          if(file.name.length > 100){
            this.$message.error(`文件名称过长，文件名称不可超过100个字符！`);
            return false;
          }
          if (file.name.lastIndexOf(".") > -1) {
            fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
          }
          const isTypeOk = this.fileType.some((type) => {
            if (file.type.indexOf(type) > -1) return true;
            if (fileExtension && fileExtension.indexOf(type) > -1) return true;
            return false;
          });
          if (!isTypeOk) {
            this.$message.error(`文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`);
            return false;
          }
        }
        // 校检文件大小
        if (this.fileSize) {
          const isLt = file.size / 1024 / 1024 < this.fileSize;
          if (!isLt) {
            this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
            return false;
          }
        }
        return true;
      },
      // 文件个数超出
      handleExceed() {
        this.$message.error(`只允许上传${this.limit}个文件`);
      },
      handleRemove(file, fileList) {//移除
        this.fileIds = [];
        fileList.forEach((item) => {
          var id = item.response ? item.response.fileId : item.fileId;
          if (id) {
            this.fileIds.push(id);
          }
        });
        this.$emit("input", this.fileIds.join(","));
      },
      handlePictureCardPreview(file) {//预览
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      handleError(err, file, fileList) {//上传失败
        this.$message.error("上传失败, 请重试");
      },
      handleAvatarSuccess(res, file, fileList) {//图片上传成功
        this.$message.success("上传成功");
        this.fileIds.push(res.fileId);
        if (this.fileList.length == 0) {
          this.fileList = fileList;
        }
        this.$emit("input", this.fileIds.join(","));
      }
    },
    created() {
      this.fileList = [];
      if (this.value) {
        //后台加载附件信息
        getFileInfo(this.value).then((result) => {
          var list = [];
          if (Array.isArray(result.data)) {
            result.data.forEach((item) => {
              var obj = {};
              obj.fileId = item.id;
              obj.name = item.fileName;
              obj.url = this.port + item.filePath;
              list.push(obj);
            });
            this.fileList = list; //把处理完的图片数据赋值给绑定的对象属性，让其回显到页面
          }
        });
      } else {
        this.fileList = [];
      }
    },
    watch: {
      value: {
        handler: function (newVal) {
          if (newVal) {
            if (this.fileList.length === 0) {
              //后台加载附件信息
              getFileInfo(this.value).then((result) => {
                var list = [];
                if (Array.isArray(result.data)) {
                  result.data.forEach((item) => {
                    var obj = {};
                    obj.fileId = item.id;
                    obj.name = item.fileName;
                    obj.url = this.port + item.filePath;
                    list.push(obj);
                  });
                  this.fileList = list; //把处理完的图片数据赋值给绑定的对象属性，让其回显到页面
                }
              });
            }
          } else {
            this.fileList = []
          }
        },
        immediate: true
      }
    },
  };
</script>

<style lang="scss">
  .image {
    position: relative;

    .mask {
      opacity: 0;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      transition: all 0.3s;
    }

    &:hover .mask {
      opacity: 0.5;
    }
  }

  .hide .el-upload--picture-card {
    display: none;
  }
</style>
